<template>
    <div class="frame-content">
        <div class="layout-left">
            <div class="filter-tree">
                <el-input
                    placeholder="搜索"
                    v-model="filterTreeText"
                    size="small">
                </el-input>
            </div>
            <div class="left-box-wrap">
                <Scrollbar style="height:100%;">
                    <div class="left-box">
                        <el-tree 
                            ref="leftTree"
                            :expand-on-click-node="false"
                            :data="treeData" 
                            :filter-node-method="filterNode"
                            default-expand-all
                            :props="{ children: 'children', label: 'label' }" 
                            @node-click="handleNodeClick">
                        </el-tree>
                    </div>
                </Scrollbar>
            </div>
        </div>
        <div class="frame-content-wrap layout-right">
            <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.name" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline ml-8">
                    <el-select v-model="filters.sex" placeholder="性别" size="small">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="number"
                            label="工号">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            label="用户名"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="sex"
                            label="性别"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.sex === 1 ? 'primary' : 'primary'"
                                    disable-transitions>{{scope.row.sex === 1 ? '男' : '女'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="mobileNumber"
                            align="center"
                            label="手机号">
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.status === 1 ? 'primary' : 'danger'"
                                    disable-transitions>{{scope.row.status === 1 ? '正常' : '禁用'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            label="创建时间"
                            align="center"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="300">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleDetail(scope.$index, scope.row)">详情</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                                <el-button
                                    v-if="scope.row.status === 1"
                                    size="mini"
                                    type="warning"
                                    @click="handleDisable(scope.$index, scope.row, 0)">禁用</el-button>
                                <el-button
                                    v-else
                                    size="mini"
                                    type="success"
                                    @click="handleDisable(scope.$index, scope.row, 1)">启用</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import Scrollbar from '@/components/Scrollbar'

export default {
    name: 'DemoLeftTree',
    components: {
        Scrollbar
    },
    data() {
        return {
            treeData: [
                {
                    id: 1,
                    label: '一级 1',
                    children: [
                        {
                            id: 11,
                            label: '二级 1-1',
                            children: [
                                {
                                    id: 111,
                                    label: '三级 1-1-1'
                                }
                            ]
                        }
                    ]
                }, {
                    id: 2,
                    label: '一级 2',
                    children: [
                        {
                            id: 21,
                            label: '二级 2-1',
                            children: [
                                {
                                    id: 211,
                                    label: '三级 2-1-1'
                                }
                            ]
                        }, {
                            id: 22,
                            label: '二级 2-2',
                            children: [
                                {
                                    id: 221,
                                    label: '三级 2-2-1'
                                }
                            ]
                        }
                    ]
                }
            ],

            nodeId: '',
            filterTreeText: '',

            filters: {
                name: '',
                sex: '',
            },
            
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            detailDialog: {
                visible: false,
                params: {},
            },

            addDialog: {
                visible: false,
            },

            editDialog: {
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    watch: {
        filterTreeText(val) {
            this.$refs.leftTree.filter(val);
        }
    },
    methods: {
        ...common,
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        loadTable() {
            let params = {...this.filters};

            params.nodeId = this.nodeId;
            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get(http.MOCK_BASE_API_URL + 'demo', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.list;
                    this.totalNum = req.data.totalCount;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        // handleNodeClick(data, node, vuecomponent) {
        handleNodeClick(data) {
            console.log(arguments);
            this.nodeId = data.id;
            this.loadTable();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        handleDetail(index, row) {
            this.detailDialog = { visible: true, params: row };
        },
        handleAdd() {
            this.addDialog = { visible: true };
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleEdit(index, row) {
            this.editDialog = { visible: true, params: row };
        },
        handleEditDone() {
            this.editDialog = { visible: false, params: {} };
            this.loadTable();
        },
        handleDisable(index, row, status) {
            http.put(http.MOCK_BASE_API_URL + 'demo/disable', { id: row.id, status: status }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess('禁用成功！');
                } else {
                    this.showError(req.data.msg);
                }
            });
        },
        handleDelete(index, row) {
            this.confirm('确认删除？', function () {
                http.delete(http.MOCK_BASE_API_URL + 'demo/' + row.id).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.delete(http.MOCK_BASE_API_URL + 'demo', { data: idarr }).then(req => {
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>
